@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {

    /*body {*/
    /*    margin-bottom: env(safe-area-inset-bottom, 0px);*/
    /*    padding-bottom: env(safe-area-inset-bottom, 0px);*/
    /*}*/

    :root {
        --sat: env(safe-area-inset-top);
        --sar: env(safe-area-inset-right);
        --sab: env(safe-area-inset-bottom); /* THIS ONE GETS US THE HOME BAR HEIGHT */
        --sal: env(safe-area-inset-left);
    }

    .no-border fieldset{
        border: none !important;
        outline: none !important;
    }

    .otp-holder input{
        width: 3rem!important;
        color: black!important;
        @apply
        h-12
        bg-gray-100
        rounded-lg
        text-black
        placeholder-666
        ml-2
        mr-2
    }

    .otp-container > div{
        @apply
        mx-auto
    }

    @media all and (display-mode: standalone) {
        .hide-when-installed {
            display: none!important;
        }

        .space-20{
            height: 20px;
            display: block;
        }
    }

    .text-4-5xl {
        font-size: 2.5rem !important;
        line-height: 2.5rem !important;
    }

    .text-vsm {
        font-size: 0.75rem !important;
        line-height: 1rem !important;
    }

    .phone-mockup {
        position: relative;
    }

    .phone-mockup img {
        padding-top: 3.6%;
        padding-bottom: 5%;
        padding-left: 3.4%;
        padding-right: 3%;
        border-radius: 20px;
        overflow: hidden;
    }

    .phone-mockup:after {
        background-image: url("/public/images/phone_frame.png");
        background-repeat: no-repeat;
        background-size: contain;
        padding-top: 10px;
        padding-left: 5px;
        padding-right: 5px;
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        content: " ";
        z-index: 2;
    }

    .w-max-body {
        max-width: 1280px;
        padding-left: 20px;
        padding-right: 20px;
        margin: auto;
    }

    @media (min-width: 768px) {
        .w-max-body {
            padding-left: 40px;
            padding-right: 40px;
        }
    }

    .input-bg {
        @apply
        h-12
        w-full
        p-5
        bg-gray-100
        rounded-lg
        text-black
        placeholder-666;
    }

    .input-btn {
        @apply
        w-full
        h-12
        bg-primary
        text-white
        font-semibold
        rounded-lg
        shadow-none
    }

    .input-btn-light {
        @apply
        w-full
        h-12
        text-black
        font-semibold
        rounded-lg
        shadow-none
        bg-eee
    }


}

[data-rsbs-header] {
    box-shadow: none !important;
}

[data-rsbs-footer] {
    padding: 0px !important;
}

.MuiChip-label {
    padding: 1.2rem !important;
}

.input-holder fieldset {
    border-radius: 0.5rem;
    border-color: #F2F4F7;
}


@keyframes bounce2 {
    from {
        transform: translate3d(0, 5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);

    }
}

@keyframes bounce3 {
    from {
        transform: translate3d(0, -5px, 0);
    }
    to {
        transform: translate3d(0, 0, 0);

    }
}